import React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

const List = styled.ul`
  list-style: none;
  margin: 0;
  color: ${props => props.theme.palette.grey[500]};
`

const ListItem = styled.li`
  display: inline;
  text-transform: capitalize;
  line-height: ${props => props.theme.typography.pxToRem(10)};
  &:after {
    margin-left: ${props => props.theme.spacing(0.5)}px;
    margin-right: ${props => props.theme.spacing(0.5)}px;
    content: "•";
  }
  &:last-child {
    &:after {
      content: "";
    }
  }
`

const Tag = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(12)};
`

export default ({ tags }) => {

  if (!tags || tags.length === 0) {
    return null
  }

  return (
    <List>
      {tags.map((tag) => (
        <ListItem key={tag}>
          <Tag component='span'>{tag}</Tag>
        </ListItem>
      ))}
    </List>
  )
}